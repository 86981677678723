import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCompositionAPI from "@vue/composition-api";
import {
  Button,
  Input,
  TreeSelect,
  InputNumber,
  Checkbox,
  Select,
} from "ant-design-vue";
import VueShortkey from "vue-shortkey";

import "ant-design-vue/dist/antd.css";

Vue.use(VueCompositionAPI);
Vue.use(VueShortkey);

Vue.component(Button.name, Button);
Vue.component(Input.name, Input);
Vue.component(Input.TextArea.name, Input.TextArea);
Vue.component(Input.Search.name, Input.Search);
Vue.component(InputNumber.name, InputNumber);
Vue.component(TreeSelect.name, TreeSelect);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Select.name, Select);
Vue.component(Select.Option.name, Select.Option);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
