import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthService from "@/serives/AuthService";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Login.vue"),
  },
  {
    path: "/manual",
    name: "Manual",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Manual.vue"),
  },
  {
    path: "/materials",
    name: "Materials",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Materials.vue"),
  },
  {
    path: "/new",
    name: "NewTaxonomy",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/NewTaxonomy.vue"),
  },
  {
    path: "",
    name: "Product",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Product.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!AuthService.isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
