import LocalStorageService from "./LocalStorageService";
import StorageKey from "@/types/StorageKey";

class AuthService {
  private readonly _localStorageService: LocalStorageService;

  constructor(localStorageService: LocalStorageService) {
    this._localStorageService = localStorageService;
  }

  public isLoggedIn(): boolean {
    const uesrname = this._localStorageService.get(StorageKey.USERNAME);
    const key = this._localStorageService.get(StorageKey.KEY);

    return uesrname && uesrname.length > 0 && key && key.length > 0
      ? true
      : false;
  }

  public logout() {
    this._localStorageService.remove(StorageKey.USERNAME);
    this._localStorageService.remove(StorageKey.KEY);
    this._localStorageService.remove(StorageKey.PRODUCTS);
  }
}

export default new AuthService(new LocalStorageService());
